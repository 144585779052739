import { render, staticRenderFns } from "./InstagramReportPage.vue?vue&type=template&id=9ce80470&scoped=true&"
import script from "./InstagramReportPage.vue?vue&type=script&lang=js&"
export * from "./InstagramReportPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ce80470",
  null
  
)

export default component.exports